import api from '../../utils/api';

const url = 'tickets';
export default {
  fetchTicketEvent: (payload) => api.get(`${url}/events/${payload}`),
  ticketCheckout: (payload) => api.post(`${url}/checkout`, payload),
  calculateTicketCost: (payload) => api.post(`${url}/calculate`, payload),
  checkIn: (payload) => api.post(`${url}/check-in`, payload),
  getListEvents: (id) => api.get(`${url}/publish-events/${id}`),
  getEventDetail: (id) => api.get(`${url}/events/${id}`),
  fetchTicket: (payload) => api.get(`${url}/${payload}`),
  reservedOrder: (orderId) =>
    api.deleteRequest(`${url}/reserved-order/${orderId}`),
};
