import api from '../../utils/api';

const url = 'gift-cards/';

export default {
  balance: (payload) => {
    const { partner: paymentProcessor, card: code } = payload;
    return api.makeEndpoint(
      'get',
      `${url}${paymentProcessor.toLowerCase()}/${code}`
    );
  },
};
